.hero{
  height: 46.2rem;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  @media(max-width: $md){
    height: 39rem;
  }

  &--xl{
    height: 83.4rem;

    @media(max-width: $md){
      height: 39rem;
    }
  }
  
  &__shadow{
    height: 28.2rem;
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%, rgba(0, 0, 0, 0) 100%);
  }

  &__mask{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;

    &::before{
      content: "";
      width: 50%;
      background-color: $blue_light;
      clip-path: polygon(0 0, 0% 100%, 100% 100%);
      height: 7.5rem;
      display: block;
      z-index: 9;
      position: absolute;
      bottom: 0;
      left: 0;

      @media(max-width: $md){
        height: 2rem;
      }

    }

    &::after{
      content: "";
      width: 50%;
      clip-path: polygon(100% 0, 0% 100%, 100% 100%);
      background-color: $blue_light;
      height: 7.5rem;
      display: block;
      z-index: 9;
      position: absolute;
      bottom: 0;
      right: 0;
      @media(max-width: $md){
        height: 2rem;
      }
    }
  }

  &--white{
    .hero{
      &__mask{
        &::before{
          background-color: $white;
        }
    
        &::after{
          background-color: $white;
        }
      }
    }
  }

  &__color{
    background: rgba(86, 146, 201, 0.25);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
  }

  &__bx{
    height: 100%;
    position: absolute;
    z-index: 9;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__tit{
    font-family: $font_lexend;
    font-weight: 700;
    font-size: 6rem;
    line-height: 6rem;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    letter-spacing: -0.02em;
    color: $white;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 2;
    position: relative;
    height: 21.8rem;
    padding: 0 2rem;
    padding-top: 21rem;

    @media(max-width: $lg){
      font-size: 5rem;
      line-height: 5rem;
      padding-top: 16rem;
    }

    @media(max-width: $md){
      font-size: 4rem;
      line-height: 4rem;
      padding-top: 13rem;
    }

    @media(max-width: $sm){
      font-size: 3rem;
      line-height: 3rem;
      padding-top: 10rem;
    }
  }

  &__arrow{
    background-image: url('../imgs/icon-carrousel-arrow-left-white.svg');
    width: 3.2rem;
    height: 6.1rem;
    z-index: 9;
    position: absolute;
    bottom: 0;
    margin-bottom: 9rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%) rotate(-90deg);

    @media(max-width: $md){
      display: none;
    }
  }
}