.footer{
  background-color: $blue;
  padding: 7rem 0 9rem;
  font-size: 1.8rem;
  line-height: 3rem;
  color: $white;

  @media (max-width: $md){
    padding: 5rem 0;
  }

  .container{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }  
  .copy{
    font-size: 1.8rem;
    line-height: 3rem;
    text-align: center;
    color: $white;
    margin: 0;
  }
}

.legal{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  @media (max-width: $md){
    flex-flow: column;
  }
  

  a{
    color: $white;
    text-decoration: none;
    font-size: 1.8rem;
    line-height: 3rem;
    text-align: center;
    color: $white;

    &:hover{
      opacity: 0.6;
    }

    &::after{
      content: '·';
      padding: 0 2rem;

      @media (max-width: $md){
        display: none;
      }
    }

    &:last-child{
      &::after{
        display: none;
      }
    }
  }

}

#button {
  display: inline-block;
  background-color: $black;
  width: 5rem;
  height: 5rem;
  text-align: center;
  border-radius: 100%;
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  transition: background-color .3s, opacity .5s, visibility .5s;
  opacity: 0;
  visibility: hidden;
  z-index: 999;

  &::after{
    content: '';
    background-image: url('../imgs/icon-backtotop.svg');
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: 2.5rem;
    background-repeat: no-repeat;
    background-position: bottom center;
  }

  &.show {
    opacity: 1;
    visibility: visible;
  }
}