.rrss{
  margin-bottom: 4.2rem;

  &__tit{
    font-family: $font_lexend;
    font-weight: 600;
    font-size: 2rem;
    line-height: 4rem;
    text-align: center;
    letter-spacing: -0.02em;
    color: $white;
    margin-bottom: 2rem;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;

    a{
      margin: 0 2rem;  

      &:hover{
        opacity: 0.6;
      }
    }
    
  }
}