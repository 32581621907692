.btn{
  width: 100%;
  max-width: 20.9rem;
  height: 4.5rem;
  font-family: $font_lexend;
  background: $white;
  border-radius: 0;
  border: 1px solid $blue;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: -0.02em;
  color: $blue;
  text-decoration: none;
  padding: 0.5rem;
  cursor: pointer;

  &:hover{
    background-color: $blue;
    color: $white;
  }

  &--xl{
    height: 11.9rem;
    max-width: 99.6rem;
    font-family: $font_lexend;
    font-weight: 600;
    font-size: 3.6rem;
    line-height: 4.5rem;
    letter-spacing: -0.02em;
    color: $blue;
  
    @media(max-width: $md){
      font-size: 2.6rem;
      line-height: 3.2rem;
      height: 6rem;
    }
  }

  &--full{
    max-width: 100%;
  }

  &--mt{
    @media(max-width: $md){
      margin-top: 4rem;
    }
  }
}

.link{
  text-decoration: none;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.4rem;
  color: $blue;
  padding-left: 0;
  position: relative;
  display: flex;
  align-items: center;

  &::before{
    content: '';
    width: 5.6rem;
    height: 0.1rem;
    background-color: $blue;
    margin-right: 1.9rem;
  }

  &--pdf{
    background-image: url('../imgs/icon-pdf.svg');
    background-repeat: no-repeat;
    background-position: center right;
    min-height: 3rem;
    display: inline-flex;
    padding-right: 4rem;
  }

  &--linkedin{
    background-image: url('../imgs/linkedin-icon.svg');
    background-repeat: no-repeat;
    background-position: center right;
    min-height: 5rem;
    display: inline-flex;
    padding-right: 5.5rem;
  }
}