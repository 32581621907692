.mdl-engagement{
  padding: 8rem 0 0;

  .item {
    display: flex;
    flex-flow: row;
    margin-bottom: 8rem;

    @media(max-width: $md){
      flex-flow: column;
      margin-bottom: 0;
    }

    &.rv{
      flex-flow: row-reverse;

      @media(max-width: $md){
        flex-flow: column;
      }

      .cl{
        &.lf{
          padding-left: 5rem;
          padding-right: 0;
  
          @media(max-width: $lg){
            padding-left: 2rem;
          }
  
          @media(max-width: $md){
            padding-left: 0;
          }
        }
  
        &.rg{
          padding-right: 5rem;
          padding-left: 0;
  
          @media(max-width: $lg){
            padding-right: 2rem;
          }
  
          @media(max-width: $md){
            padding-right: 0;
          }
        }
      }
    }

    .cl{
      width: 50%;
      display: flex;
      align-items: center;

      @media(max-width: $md){
        width: 100%;
      }

      &.lf{
        padding-right: 5rem;

        @media(max-width: $lg){
          padding-right: 2rem;
        }

        @media(max-width: $md){
          padding-right: 0;
          padding-bottom: 3rem;
        }
        img{
          width: 100%;
        }
      }

      &.rg{
        padding-left: 5rem;

        @media(max-width: $lg){
          padding-left: 2rem;
        }

        @media(max-width: $md){
          padding-left: 0;
          padding-bottom: 3rem;
        }
      }

      .text{
        h1,h2,h3,h4,h5,h6{
          font-size: 4rem;
          line-height: 5rem;
          font-family: $font_lexend;
          margin-bottom: 2rem;
          font-weight: 700;
          color: $blue;
          max-width: 80%;
          letter-spacing: -2px;
        }

        p{
          margin-bottom: 2rem;
          color: #505050;
        }
        
        u{
          font-size: 2rem;
          line-height: 3rem;  
          text-decoration: none;
        }
      }
    }
  }
}

.mdl-engage-posts{
  padding-bottom: 2rem;
  .title{
    margin-bottom: 3.4rem;
  }

  .bx{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 4rem;
    margin-bottom: 7.4rem;

    @media(max-width: $lg){
      margin-bottom: 5rem;
      grid-template-columns: repeat(1,1fr);
    }
  }

  .item{

    .img{
      margin-bottom: 4rem;
      padding-bottom: 63.5%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    .tit{
      margin-bottom: 2rem;
      font-family: $font_lexend;
      font-size: 2rem;
      line-height: 2.5rem;
      font-weight: 700;
      letter-spacing: -0.02rem;
    }

    .text{
      margin-bottom: 2rem;
      color: #505050;
      p{
        margin-bottom: 2rem;
        font-size: 1.6rem;
        line-height: 2.6rem;
      }

      strong{
        font-weight: 600;
      }
    }
  }

  .btn{
    max-width: 32.7rem;
    margin: 0 auto;
  }
}