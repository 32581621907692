html {
  /* 1rem = 10px */
  font-size: 62.5%;
}

body{
  font-size: 1.6rem;
  line-height: 2rem;
  font-family: $font_opensans; 
  font-weight: 400;

  &.blocked{
    overflow: hidden;
  }
}

.container{
  margin: 0 auto;
  max-width: 133rem;
  padding: 0 2rem; 
}


button, 
input, 
a {
  outline: none;
  cursor: pointer;
  transition: all 300ms linear;
  text-decoration: none;
  color: inherit
}

a{
  color: $blue;
  font-weight: 600;
}

.center{
  text-align: center;
}

strong,
b{
  font-weight: 700;
}

p,
ul,
ol{
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.6rem;
  color: inherit;
  margin-bottom: 1.6rem;
}

ul{
  list-style: disc;
  padding-left: 2rem;
}

ol{
  list-style: decimal;
  padding-left: 2rem;
}

.page-content{
  margin: 10rem 0 8rem;

  @media(max-width: $lg){
    margin: 5rem 0;
  }

  @media(max-width: $md){
    margin: 3rem 0;
  }

  strong,
  b{
    font-weight: 700;
  }

  p,
  ul,
  ol{
    font-weight: 400;
    font-size: 2rem;
    line-height: 3rem;
    color: #505050;
    margin-bottom: 2rem;
  }

  ul{
    list-style: disc;
    padding-left: 2rem;
  }

  ol{
    list-style: decimal;
    padding-left: 2rem;
  }

  a{
    font-weight: 600;
    color: $blue;
    text-decoration: none;
  }

  @mixin typo {
    font-family: $font_lexend;
    font-weight: 700;
    letter-spacing: -0.02em;
    color: $blue;
  }

  h1{
    @include typo;
    font-size: 4rem;
    line-height: 5rem;
    margin-bottom: 4rem;
  }

  h2{
    @include typo;
    font-size: 3.6rem;
    line-height: 4.6rem;
    margin-bottom: 3.6rem;
  }

  h3{
    @include typo;
    font-size: 3.2rem;
    line-height: 4.2rem;
    margin-bottom: 3.2rem;
  }

  h4{
    @include typo;
    font-size: 2.8rem;
    line-height: 3.8rem;
    margin-bottom: 2.8rem;
  }

  h5{
    @include typo;
    font-size: 2.4rem;
    line-height: 3.4rem;
    margin-bottom: 2.4rem;
  }

  h6{
    @include typo;
    font-size: 2rem;
    line-height: 3rem;
    margin-bottom: 2rem;
  }

  table {
    border-collapse: collapse;
    color: #505050;
  }

  th {
    background: $blue;
    color: $white;
    font-weight: 700;
  }
  
  th, td {
    // border: 1px solid rgba(86, 146, 201, 0.1);
    padding: 1rem;
  }

  tr:nth-child(odd) {
    background: rgba(86, 146, 201, 0.3);
    &:nth-child(1) {
      background: $blue;
      color: $white;
      font-weight: 700;
    }
  }
  
}

.section{
  padding: 8rem 0;

  @media(max-width: $lg){
    padding: 4rem 0;
  }

  &--sm{
    padding: 5rem 0;
    @media(max-width: $lg){
      padding: 4rem 0;
    }
  }
}

.bg{
  background-color: $blue_light;
}

.title{
  font-family: $font_lexend;
  font-weight: 700;
  font-size: 4rem;
  line-height: 5rem;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: -0.02em;
  color: #222222;
  margin-bottom: 5rem;
  padding-bottom: 0rem;
  position: relative;

  @media(max-width: $lg){
    font-size: 3rem;
    line-height: 4rem;
    margin-bottom: 3rem;
    padding-bottom: 2rem;
  }

  @media(max-width: $md){
    font-size: 2.5rem;
    line-height: 3.4rem;
    margin-bottom: 2.5rem;
    padding-bottom: 2rem;
  }

  &--md{
    font-size: 3.5rem;
    line-height: 4.5rem;
    margin-bottom: 3rem;

    @media(max-width: $lg){
      font-size: 3rem;
      line-height: 4rem;
      margin-bottom: 3rem;
    }
  
    @media(max-width: $md){
      font-size: 2.5rem;
      line-height: 2.5rem;
      margin-bottom: 2.5rem;
    }
  }

  &--sm{
    font-size: 3rem;
    line-height: 3.8rem;

    @media(max-width: $lg){
      font-size: 2.5rem;
      line-height: 3.2rem;
    }

    @media(max-width: $md){
      font-size: 2rem;
      line-height: 2.6rem;
    }

  }

  &--lf{
    justify-content: flex-start;
    text-align: left;

    &::after{
      width: 8.7rem;
    }

  }

  &--rg{
    justify-content: flex-end;
    text-align: right;

    &::after{
      width: 8.7rem;
    }

  }
  
  &--line{
    padding-bottom: 2rem;

    &::after{
      content: '';
      width: 11.4rem;
      height: 0.3rem;
      background-color: $blue;
      display: block;
      position: absolute;
      bottom: 0;

      @media(max-width: $lg){
        width: 8rem;
      }

      @media(max-width: $md){
        width: 6rem;
      }
    }
  }



  &--blue{
    color: $blue;
  }
}

.tns-controls{
  margin-bottom: 3.7rem;
  max-width: 15rem;
  width: 100%;
  display: flex;
  justify-content: space-between;

  button{
    width: 6rem;
    height: 3.2rem;
    background-image: url('../imgs/icon-carrousel-arrow-right.svg');
    border: 0;
    padding: 0;
    margin: 0;
    background-color: transparent;
    cursor: pointer;
    color: transparent;

    &[data-controls="prev"]{
      transform: rotate(180deg);
    }
  }
}

// .navigation{
//   margin-bottom: 3.7rem;
//   max-width: 15rem;
//   display: flex;
//   justify-content: space-between;
  
//   &__bt{
//     width: 6rem;
//     height: 3.2rem;
//     background-image: url('../imgs/icon-carrousel-arrow-right.svg');
//     border: 0;
//     padding: 0;
//     margin: 0;
//     background-color: transparent;
//     cursor: pointer;

//     &--p{
//       transform: rotate(180deg);
//     }
//   }
// }

.certificates{

  .container{
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;
  }

  .title{
    font-size: 2rem;
    line-height: 4rem;
    color: $white;
    margin-bottom: 2rem;
    font-weight: 600;
  }

  &__bx{
    max-width: 87.4rem;
    margin: 0 auto;
    margin-bottom: 4rem;
    width: 100%;
    // grid-template-columns: repeat(6, 1fr);
    // display: grid;
    // grid-gap: 2rem;
    gap: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    img{
      width: auto;
      max-height: 6.5rem;
    }

    @media(max-width:$md){
      // grid-template-columns: repeat(2,1fr);
    }
  }
}

.m-grid{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 0.5rem;
  margin-bottom: 5.3rem;
  max-width: 133rem;

  @media(max-width:$md){
    grid-template-columns: repeat(1,1fr);
  }

  &--th{
    grid-template-columns: repeat(3,1fr);
    @media(max-width:$md){
      grid-template-columns: repeat(1,1fr);
    }
    .m-grid{
      &__tit{
        font-size: 2.6rem;
        line-height: 3.2rem;
      }
    }
  }

  &__it{
    padding-bottom: 65%;
    position: relative;
    overflow: hidden;

    &:hover{
      .m-grid{
        &__bg{
          display: none;
        }

        &__img{
          transform: scale(1.1);
        }

        &__tit{
          color: #222222;
        }

        &__link{
          display: block;
        }
      }
    }
  }

  &__bg{
    background-color: $blue;
    opacity: 0.6;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__img{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 300ms linear;
  }

  &__bx{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
  }

  &__tit{
    font-family: $font_lexend;
    font-weight: 700;
    font-size: 3.6rem;
    line-height: 4.5rem;
    text-align: center;
    letter-spacing: -0.02em;
    color: $white;
    max-width: 52.3rem;
    margin: 0 auto;
    padding: 4.3rem;
    width: 100%;

    span{
      font-size: 1.6rem;
      line-height: 2rem;
      display: flex;
      text-align: center;
    }

    
    @media(max-width: $xxl){
      font-size: 3.2rem;
      line-height: 4rem;
      padding: 2rem;
    }

    
    @media(max-width: $lg){
      font-size: 2.6rem;
      line-height: 3.2rem;
    }

    @media(max-width:$md){
      padding: 2rem; 
    }

  }

  &__link{
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    // background-image: url('../imgs/icon-plus.svg');
    // background-repeat: no-repeat;
    // background-position: bottom 5.3rem right 3.5rem;
    display: none;

    @media(max-width: $lg){
      // background-size: 6.6rem;
      // background-position: bottom 2rem right 2rem;
    }
  }

  &.has-scroll{
    .m-grid{
      &__link{
        &:hover{
          cursor: url(../imgs/icon-plus.svg), auto;
        }
      }
    }
  }

}

.shake-vertical {
	-webkit-animation: shake-vertical 10s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
	        animation: shake-vertical 10s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
}

@-webkit-keyframes shake-vertical {
  0%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
  }
  80% {
    -webkit-transform: translateY(6.4px);
            transform: translateY(6.4px);
  }
  90% {
    -webkit-transform: translateY(-6.4px);
            transform: translateY(-6.4px);
  }
}
@keyframes shake-vertical {
  0%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
  }
  80% {
    -webkit-transform: translateY(6.4px);
            transform: translateY(6.4px);
  }
  90% {
    -webkit-transform: translateY(-6.4px);
            transform: translateY(-6.4px);
  }
}

.video-responsive {
  position: relative;
  padding-bottom: 56.25%; /* 16/9 ratio */
  padding-top: 30px; /* IE6 workaround*/
  height: 0;
  overflow: hidden;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}