.patient-info{
  &__q{
    font-family: $font_lexend;
    font-weight: 700;
    font-size: 4rem;
    line-height: 5rem;
    letter-spacing: -0.02em;
    color: $blue;
    border-bottom: 1px solid $blue;
    padding: 2rem 0;
    margin-bottom: 2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media(max-width: $lg){
      font-size: 3rem;
      line-height: 4rem;
    }

    &::after{
      content: '';
      background-image: url('../imgs/icon-acordeon-arrow-down.svg');
      width: 100%;
      max-width: 3.3rem;
      height: 1.9rem;
      display: block;
      margin-left: 1rem;
      transition-duration: 0.3s;
      transition-property: transform;
    }

    &.js-toggle{
      &::after{
        transform: rotate(180deg);
      }
    }
  }

  &__a{
    display: flex;
    flex-flow: row;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.6rem;
    color: #000000;
    padding: 2rem 0 6rem;
    display: none;

    @media(max-width: $md){
      flex-flow: column;
    }

    &.js-toggle{
      display: flex;
    }

    img{
      width: 100%;
    }

    p{
      margin-bottom: 2.6rem;
    }
  }

  &__desc{
    padding-left: 8.6rem;

    @media(max-width: $lg){
      padding-left: 2rem;
    }
    @media(max-width: $md){
      padding-left: 0;
    }
  }

  &__cl{
    width: 50%;

    @media(max-width: $md){
      width: 100%;
    }

    &--lf{
      padding-right: 2rem;
      @media(max-width: $md){
        padding-right: 0;
        margin-bottom: 3rem;
      }
    }

    &--full{
      width: 100%;
    }
  }
}

.legal-advice{
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.6rem;
  color: #505050;

  .container{
    display: flex;
    flex-flow: row;
    align-items: center;
    @media(max-width: $md){
      flex-flow: column;
    }
  }
  &__cl{
    &--lf{
      width: 20%;
      padding-right: 8rem;

      @media(max-width: $lg){
        padding-right: 4rem;
        width: 30%;
      }
      @media(max-width: $md){
        padding-right: 0;
        width: 100%;
        padding-bottom: 4rem;
        text-align: center;
      }
    }

    &--rg{
      width: 80%;
      padding: 8rem 0;
      padding-left: 8rem;
      border-left: 1.5px solid rgba(86, 146, 201, 0.2);

      @media(max-width: $lg){
        padding: 4rem 0;
        padding-left: 4rem;
        width: 70%;
      }

      @media(max-width: $md){
        padding-left: 0;
        width: 100%;
        border-left: 0;
        border-top: 1.5px solid rgba(86, 146, 201, 0.2);
        text-align: center;
      }
    }
  }
  &__tit{
    font-family: $font_lexend;
    font-weight: 700;
    font-size: 2.6rem;
    line-height: 3.2rem;
    color: #222222;
  }
}

.more-information{

  &__bx{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 4rem;

    @media(max-width: $md){
      grid-template-columns: repeat(1,1fr);

    }
  }

  &__it{
    background: rgba(80, 80, 80, 0.05);
    font-size: 1.6rem;
    line-height: 2.6rem;
    color: #505050;
    background-image: url('../imgs/icon-masinformacion--linkweb.svg');
    background-repeat: no-repeat;
    background-position: center left 4.5rem;
    padding: 3.4rem 4.5rem 3.4rem 17.6rem;

    @media(max-width: $lg){
      background-position: top 3.4rem left 4.5rem;
      padding: 14.6rem 4.5rem 3.4rem;
    }
  }

  &__tit{
    font-family: $font_lexend;
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.5rem;
    letter-spacing: -0.02em;
    color: #222222;
    margin-bottom: 2rem;
  }
  &__txt{
    margin-bottom: 3.9rem;
  }
}

.contact-floating{
  background-color: $blue;
  position: fixed;
  z-index: 9;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-family: $font_lexend;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  box-shadow: 0px 8px 8px rgb(34 34 34 / 10%);
  border-radius: 20px 0px 0px 20px;
  height: 12rem;
  text-decoration: none;
  background-image: url('../imgs/icon-contacteconnosotros.svg');
  width: 100%;
  max-width: 12rem;
  background-repeat: no-repeat;
  background-position: center left 3rem;

  span{
    display: none;
  }

  &:hover{
    max-width: 38.2rem;
    span{
      display: flex;
      padding-left: 13rem;
    }
  }
}