.header{
  padding: 4.4rem 0 0;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: transparent;
  z-index: 99;
  color: $white;

  

  .container{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 100%;
  }

  &__cl{
    display: flex;
    flex-flow: row;
    align-items: flex-start;
    width: 33.333%;

    &--full{
      width: 100%;
      justify-content: center;
    }

    &--lf{
      width: 40%;
      @media(max-width: $xl){
        flex-flow: column;
      }

      @media(max-width: $xl){
        display: none;
      }
      
    }

    &--cn{
      width: 20%;
      justify-content: center;

      @media(max-width: $xl){
        width: 100%;
        align-items: center;
      }
    }

    &--rg{
      width: 40%;
      justify-content: flex-end;
      @media(max-width: $xl){
        display: none;
      }
    }
  }

  .logo{
    position: relative;

    a{
      position: absolute;
      width: 100%;
      height: 100%;
    }

    img{
      max-width: 18rem;
      width: 100%;
      margin-top: 2.1rem;

      @media(max-width: $xl){
        max-width: 14.4rem;
        margin-top: 0;
      }
    }
  }

  &.js-sticky{
    background-color: $blue;
    padding: 4rem 0;

    @media(max-width: $xl){
      padding: 2rem 0;
    }

    .logo{
      img{
        max-width: 9rem;
        margin-top: 0;
      }
    }

    .header{

      &__cl{
        align-items: center;

        &--cn{
          justify-content: flex-start;
          width: 10%;

          @media(max-width: $xl){
            justify-content: space-between;
            width: 100%;
          }

          .m-toggle{
            @media(max-width: $xl){
              right: auto;
            }
          }
        }
        &--lf{
          display: none;
        }
        &--rg{
          display: none;
        }

        &--full{
          justify-content: end;
          width: 90%;
        }
      }
    }

    .menu{
      margin-top: 0;

      .menu-item{
        margin: 0 1rem;
        &.submenu{
          margin-right: 2.5rem;
        }
      }
    }
  }

}


.info-item{
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.9rem;
  color: $white;
  padding-left: 2.7rem;
  text-decoration:none;
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 1.5rem;
  margin-right: 3rem;

  &--mail{
    background-image: url('../imgs/icon-top-email.svg');
  }

  &--tel{
    background-image: url('../imgs/icon-top-phone.svg');
  }

  &--log{
    background-image: url('../imgs/icon-top-login.svg');
    text-transform: uppercase;
  }

  &--log-in{
    background-image: url('../imgs/icon-top-user.svg');
    text-transform: uppercase;
    background-size: 1.1rem;
    padding-left: 2.5rem;
    margin-right: 1.5rem;
  }

  &--log-out{
    background-image: url('../imgs/icon-top-logout.svg');
    text-transform: uppercase;
    width: 1.5rem;
    height: 2rem;
    padding: 0;
  }

  &:hover{
    opacity: 0.6;
  }
}

.log-items{
  display: flex;
  flex-flow: row;
  align-items: center;
}


.lang-sel{
  text-transform: uppercase;
  position: relative;
  font-size: 1.4rem;
  line-height: 1.9rem;
  font-weight: 600;

  &__current{
    padding-right: 2rem;
    cursor: pointer;

    &::after{
      content: '';
      background-image: url('../imgs/icon-top-down.svg');
      width: 1.5rem;
      height: 1.5rem;
      background-size: 1.5rem;
      background-repeat: no-repeat;
      background-position: center right;
      display: block;
      position: absolute;
      right: 0;
      top: 0.4rem;
      transition-duration: 0.3s;
      transition-property: transform;
    }
    &.js-show{
      &::after{
        transform: rotate(180deg);
      }
    }
  }  

  &__toggle{
    display: none;  
    position: absolute;
    right: 0;
    background-color: $white;
    flex-flow: column;
    box-shadow: 0 0 1rem #ccc;
    border-radius: 0.3rem;
    padding: 1rem 2rem;
    margin-top: 1rem;

    &::after {
      content: " ";
      position: absolute;
      right: 1.5rem;
      top: -1rem;
      border-top: none;
      border-right: 1rem solid transparent;
      border-left: 1rem solid transparent;
      border-bottom: 1rem solid $white;
    }

    &.js-show{
      display: flex;
    }
  }

  &__item{
    color: $black;
    text-decoration: none;
  }
}
