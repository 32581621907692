.prod-intro{
  padding: 2.3rem 0 11rem;

  .container{
    display: flex;
    flex-flow: row;
    padding-left: 0;
    max-width: 160rem;

    @media(max-width: $md){
      flex-flow: column;
      padding-left: 2rem;
    }
  }

  &__cl{
    width: 50%;

    @media(max-width: $md){
      width: 100%;
    }

    &--lf{
      margin-right: 2rem;
      padding-top: 7.2rem;
      position: relative;

      @media(max-width: $lg){
        padding-top: 0;
      }
      @media(max-width: $md){
        margin-bottom: 3rem;
      }

      .prod-intro{
        &__img{
          position: sticky;
          top: 15rem;
        }
      }

    }
  }

  &__bx{
    max-width: 57.9rem;
    padding-left: 8rem;

    @media(max-width: $lg){
      padding-left: 0;
      max-width: 100%;
    }
  }

  &__img{
    img{
      width: 100%;
    }
  }

  &__tit{
    font-family: $font_lexend;
    font-weight: 700;
    font-size: 4rem;
    line-height: 5rem;
    letter-spacing: -0.02em;
    color: $blue;
    margin-bottom: 3.4rem;

    span{
      font-size: 2.4rem;
      line-height: 3rem;

      @media(max-width: $lg){
        font-size: 2rem;
        line-height: 2.6rem;
      }

      @media(max-width: $md){
        font-size: 1.8rem;
        line-height: 2.4rem;
      } 
    }
  }

  &__des{
    font-size: 1.6rem;
    line-height: 2.6rem;
    color: #505050;
    margin-bottom: 5rem;

    p{
      font-size: 1.6rem;
      line-height: 2.6rem;
      color: #505050;
      margin-bottom: 2.6rem;

      &:first-child{
        font-size: 2rem;
        line-height: 3rem;
        margin-bottom: 3rem;
      }
    }

    &--nm{
      p{
        &:first-child{
          font-size: 1.6rem;
          line-height: 2.6rem;
          margin-bottom: 2.6rem;
        }
      }
    }

    img {
      width: auto;
      height: auto;
      max-width: 100%;
    }

  }
}

.prod-docs{
  padding: 10rem 0;
  background-color: $blue_light;
}

.video-list {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 4rem;
  font-size: 2rem;
  line-height: 3rem;
  color: #505050;

  @media(max-width:$md){
    grid-template-columns: repeat(1,1fr);
  }

  a{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    top: 0;
    left: 0;
  }

  &__it{
    position: relative;
  }

  &__img{
    padding-bottom: 63%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 5.2rem;
    position: relative;
    @media(max-width:$md){
      margin-bottom: 2rem;
    }
  }

  &__tit{
    font-family: $font_lexend;
    font-weight: 400;
    font-size: 2.6rem;
    line-height: 3.2rem;
    color: #000000;
    margin-bottom: 2rem;
  }

  &__txt{
    font-size: 2rem;
    line-height: 3rem;
    color: #505050;
    
    span{
      color: $blue
    }
  
  }

  &__link{
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('../imgs/icon-play.svg');
    background-repeat: no-repeat;
    background-position: center;
  }

}