.about-intro{
  font-size: 2rem;
  line-height: 3rem;
  text-align: center;
  color: #505050;

  &__txt{
    max-width: 103rem;
    margin: 0 auto;
    margin-bottom: 4rem;
    & strong, & b{
      font-weight: 600;
    }
  }
}

.about-desc{
  display: flex;
  flex-flow: row;
  padding: 4rem 0;
  font-size: 1.6rem;
  line-height: 2.6rem;

  @media(max-width: $lg){
    flex-flow: column;
    padding: 4rem 0 0;
  }

  &--rv{
    flex-flow: row-reverse;

    @media(max-width: $lg){
      flex-flow: column;
    }

    .about-desc{
      &__cl{
        &--lf{
          padding-left: 5rem;
          padding-right: 0;
  
          @media(max-width: $lg){
            padding-left: 2rem;
          }
  
          @media(max-width: $md){
            padding-left: 0;
          }
        }
  
        &--rg{
          padding-right: 5rem;
          padding-left: 0;
  
          @media(max-width: $lg){
            padding-right: 2rem;
          }
  
          @media(max-width: $md){
            padding-right: 0;
          }
        }
      }
    }
  }

  &__cl{
    width: 50%;
    align-items: center;
    display: flex;
    justify-content: center;

    @media(max-width: $lg){
      width: 100%;
    }

    &--lf{
      padding-right: 5rem;

      @media(max-width: $lg){
        padding-right: 2rem;
      }

      @media(max-width: $md){
        padding-right: 0;
        padding-bottom: 3rem;
      }
      img{
        width: 100%;
      }
    }

    &--rg{
      padding-left: 5rem;

      @media(max-width: $lg){
        padding-left: 2rem;
      }

      @media(max-width: $md){
        padding-left: 0;
        padding-bottom: 3rem;
      }
    }
  }

  &__txt{
    text-align: left;
    padding: 0;

    strong, b{
      font-weight: 600;
    }

    p{
      margin-bottom: 2rem;
      font-size: 1.6rem;
      line-height: 2.6rem;

      &:last-child{
        margin-bottom: 0;
      }
    }

    h3{
      font-family: $font_lexend;
      font-size: 4rem;
      font-weight: 700;
      line-height: 5rem;
      letter-spacing: -0.02em;
      text-align: left;
      margin-bottom: 2rem;
      color: $blue;
    }

    u{
      text-decoration: none;
      font-size: 2rem;
      line-height: 3rem;
    }
  }
}

.about-items{
  &__bx {
    display: flex;
    flex-flow: row;
    margin: 0 auto;
    margin-bottom: 8rem;
    align-items: center;
    justify-content: center;
    align-items: stretch;

    &:last-child{
      margin-bottom: 0;
    }

    @media(max-width: $lg){
      margin-bottom: 4rem;
    }

    @media(max-width: $md){
      flex-flow: row;
      flex-wrap: wrap;
    }
  }

  &__cl{
    width: 50%;
    align-items: end;
    display: flex;
    flex-flow: column;
    padding: 0 8rem;

    @media(max-width: $lg){
      width: 100%;
      padding: 0 4rem;
    }

    @media(max-width: $md){
      padding: 0;
    }

    &--lf{
      padding-top: 10rem;
      padding-left: 0;

      @media(max-width: $lg){
        padding-top: 5rem;
      }

      @media(max-width: $md){
        padding-top: 0;
        align-items: flex-start;
      }
    }
    

    &--rg{
      border-left: 1.5px solid rgba(86, 146, 201, 0.2);
      padding-right: 0;

      @media(max-width: $md){
        border: 0;
      }
    }
  }

  &__txt{
    text-align: center;
    font-size: 2rem;
    line-height: 3rem;
    color: #000000;
    max-width: 73.6rem;
    margin: 0 auto;

    @media(max-width: $md){
      max-width: 100%;
      margin-bottom: 3rem;
    }

    strong,b{
      font-weight: 600;
    }
  }

  &__it{
    background-color: $white;
    width: 100%;
    margin-bottom: 2.3rem;
    min-height: 12rem;
    display: flex;
    align-items: center;
    font-size: 2rem;
    line-height: 3rem;
    color: #000000;

    p{
      padding: 1.4rem 3.4rem;
    }

    strong{
      font-family: $font_lexend;
      font-weight: 600;
      font-size: 2.6rem;
      line-height: 3rem;
      color: $blue;
    }

    &:last-child{
      margin-bottom: 0;
    }

    &--img{
      font-family: $font_lexend;
      font-weight: 600;
      font-size: 2rem;
      line-height: 2.5rem;
      letter-spacing: -0.02em;
      color: #222222;
      display: flex;
      flex-flow: column;
      padding: 4rem 3rem;
      width: calc(20% - 2rem);
      margin: 0 1rem;
      max-width: 24.2rem;
      box-sizing: border-box;

      @media(max-width: $lg){
        width: 100%;
        margin-bottom: 2rem;
      }

      p{
        padding: 2rem 0 0;
        font-family: $font_lexend;
        font-size: 1.8rem;
        line-height: 2rem;
        margin: 0;
        font-weight: 600;
        text-align: center;

        .primera-letra {
          color: $white;
          background-color: $blue; /* Color de fondo personalizado */
          width: 4rem; /* Ancho personalizado */
          height: 4rem; /* Alto personalizado */
          border-radius: 50%;
          display: inline-flex; /* Para permitir que el ancho y alto funcionen */
          margin-right: 0; /* Añadir margen a la derecha para separarla del resto del texto */
          font-size: 3rem; /* Tamaño de fuente opcional para la primera letra */
          line-height: 3.7rem;
          justify-content: center;
          align-items: center;
          margin-right: 0.5rem;
        }
        
      }

    }
  }

  &__img{
    width: 9rem;
    height: 9rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 4.7rem 0 4rem;

    @media(max-width: $lg){
      width: 6rem;
      height: 6rem;
      margin: 0 3rem 0 4rem;
    }
  }

  .container{
    padding-bottom: 7rem;
    
    &.mision{

    }

    &.vision{
    }

    &.values{
      .bx{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12rem 0 8rem;
        flex-flow: column;

        @media(max-width: $md){
          padding: 0;
          flex-flow: column-reverse;
        }
      }

      img{
        position: absolute;
        @media(max-width: $md){
          position: relative;
          margin-top: 3rem;
        }
      }
    }
  }
}

@mixin distribute-on-circle( 
  $nb-items,
  $circle-size,
  $item-size,
  $class-for-IE: false
) {
  $half-item: ($item-size / 2);
  $half-parent: ($circle-size / 2);
  
  position: relative; /* 1 */
  width:  $circle-size;
  height: $circle-size;
  padding: 0;
  border-radius: 50%; 
  list-style: none; /* 2 */ 
  box-sizing: content-box; /* 3 */ 
  
  > * { /* 4 */
    display: block;
    position: absolute;
    top:  50%; 
    left: 50%;
    width:  $item-size;
    height: $item-size;
    margin: -$half-item;
    background-color: $white;
    border-radius: 50%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    box-sizing: border-box;
  }
  
  $angle: (360 / $nb-items);
  $rot: 0;

  @for $i from 1 through $nb-items {
    @if not $class-for-IE {
      > :nth-of-type(#{$i}) {
        transform: rotate($rot * 1deg) translate($half-parent) rotate($rot * -1deg);
      }
    } @else {
      > .#{$class-for-IE}#{$i} {
        // If CSS transforms are not supported
        $mt: sin($rot * pi() / 180) * $half-parent - $half-item;
        $ml: cos($rot * pi() / 180) * $half-parent - $half-item;
        margin: $mt 0 0 $ml;
      }
    }

    $rot: ($rot + $angle);
  }
}

.circle-container {
  @include distribute-on-circle(7, 43em, 15.25em, false); 
  margin: 0 auto;

  @media(max-width: $lg){
    @include distribute-on-circle(7, 33em, 13em, false); 
    
  }

  @media(max-width: $md){
    display: flex;
    flex-wrap: wrap;
    width: auto;
    height: auto;
    margin-top: 3rem;
  }

  .item{
    text-align: center;

    @media(max-width: $md){
      position: relative;
      transform: none;
      top: auto;
      left: auto;
      margin: 0 auto 2rem;      
    }

    p{
      font-style: italic;
      font-size: 1.6rem;
      line-height: 2rem;
      color: #000000;
      margin: 0;

      @media(max-width: $lg){
        font-size: 1.6rem;
        line-height: 2.6rem;
      }

      &.tit{
        font-family: $font_lexend;
        font-style: normal;
        font-weight: 600;
        margin-bottom: 1rem;

        @media(max-width: $lg){
          margin-bottom: 1.6rem;
        }

      }
    }
  }
}

.organization{
  &__img{
    img{
      width: 100%;
    }

    &--des{
      display: block;
      @media(max-width: $md){
        display: none;
      }
    }

    &--mob{
      display: none;
      @media(max-width: $md){
        display: block;
      }
    }
  }
}