.log-intro{
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  color: #505050;
  margin-bottom: 6rem;

  p{
    margin-bottom: 2rem;
  }
  
  strong{
    font-weight: 600;
  }
}

.log-cn{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 4.6rem 10rem ;
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  color: #505050;

  @media(max-width: $md){
    grid-template-columns: repeat(1,1fr);
    grid-gap: 5rem;
  }

  p{
    margin-bottom: 2rem;

    &.log-cn{
      &__tit{
        font-weight: 700;
        font-family: $font_lexend;
        font-size: 2.6rem;
        line-height: 3.2rem;
        color: $blue;
        margin-bottom: 4.6rem;
      }
    }
  }
  
  strong{
    font-weight: 600;
  }

}