.certifications-intro{
  padding: 6.3rem 0 11.5rem;


  @media(max-width: $lg){
    padding: 5rem 0;
  }

  &__i{
    font-size: 2rem;
    line-height: 3rem;
    text-align: center;
    color: #505050;
    max-width: 103rem;
    margin: 0 auto;
    margin-bottom: 8rem;
  }
}

.certifications-cn{
  display: flex;
  flex-flow: row;

  @media(max-width: $md){
    flex-flow: column;
  }

  &__cl{
    width: 50%;
    display: flex;
    align-items: center;

    @media(max-width: $md){
      width: 100%;
    }

    &--rg{
      justify-content: flex-end;
      padding: 2rem 0;
    }
  }

  &__img{
    width: 100%;
    padding-bottom: 72.917%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  &__desc{
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.6rem;
    color: #505050;
    max-width: 56.7rem;
    padding: 0 2rem;

    @media(max-width: $md){
      max-width: 100%;
      padding: 0;
    }

    p{
      margin-bottom: 2.6rem;
    }

    b{
      font-weight: 600;
    }

  }
}

.certifications-list{
  padding-top: 0;
  .container{
    padding-top: 10rem;
    border-top: 1.5px solid rgba(86, 146, 201, 0.2);
  }
}

.down-docs{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  grid-gap: 4rem;

  @media(max-width:$lg){
    grid-template-columns: repeat(3,1fr);

  }

  @media(max-width:$md){
    grid-template-columns: repeat(2,1fr);

  }

  @media(max-width:$sm){
    grid-template-columns: repeat(1,1fr);

  }
  
  &__it{
    position: relative;

    a{
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 9;
    }

    &:hover{
      .down-docs{
        &__bg{
          display: none;
        }

        &__a{
          display: block;
        }
      }
    }
  }
  
  &__img{
    padding-bottom: 141%;
    margin-bottom: 1.6rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  &__a{
    background-image: url('../imgs/icon-download.svg');
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 12.8rem;
    display: none;
  }

  &__bg{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(86,146,201,0.5);
  }

  p{
    font-family: $font_lexend;
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.5rem;
    text-align: center;
    letter-spacing: -0.02em;
    color: #222222;
  }
}