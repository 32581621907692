.h-intro{
  padding: 10rem 0 0;
  margin-top: 0rem;
  background-color: $blue_light;
  position: relative;

  @media(max-width: $md){
    padding: 5rem 0;
  }

  &::before{
    content: '';
    background-color: $white;
    height: 9rem;
    width: 100%;
    bottom: 0;
    display: block;
    position: absolute;
    
    @media(max-width:$sm){

    }
  }

  .container{
    display: flex;
    flex-flow: row;
    max-width: 152rem;

    @media(max-width: $md){
      flex-flow: column-reverse;
    }

  }

  &__cl{
    width: 50%;

    @media(max-width: $md){
      width: 100%;
    }

    &--rg{
      padding: 0 0 0 2rem;
      display: flex;
      justify-content: center;
      @media(max-width: $md){
        padding: 0;
      }
    }
  }

  &__bx{
    max-width: 56.7rem;
    @media(max-width: $md){
      max-width: 100%;
      margin-bottom: 5rem;
    }
  }

  &__tit{
    font-family: $font_lexend;
    font-weight: 700;
    font-size: 4rem;
    line-height: 5rem;
    letter-spacing: -0.02em;
    color: $blue;
    margin-bottom: 3.2rem;

    @media(max-width: $md){
      font-size: 3rem;
      line-height: 3.8rem;
      margin-bottom: 1.3rem;
    }
  }

  &__txt{
    font-weight: 400;
    font-size: 2rem;
    line-height: 3rem;
    color: #505050;
    p{
      font-size: 2rem;
      line-height: 3rem;
      color: #505050;
      margin-bottom: 3rem;
    }
  }
}

.h-intro-slide{
  &__it{
    img{
      width: 100%;
      display: block;
    }
  }
}

.rel-profesional{
  padding: 11rem 0;
  display: flex;
  flex-flow: column;
  align-items: center;

  @media(max-width:$lg){
    padding: 5rem 0 8rem;
  }

  .title{
    @media(max-width:$lg){
      padding: 0 2rem 2rem 2rem;
    }
  }

  .btn{
    @media(max-width:$lg){
      max-width: calc(100% - 5rem);
    }
  }
}