.m-blog-rel{
  padding-bottom: 9.5rem;

  @media(max-width: $lg){
    padding-bottom: 8rem;
  }

  .tns-outer{
    display: flex;
    flex-flow: column;

    .tns-ovh{
      order: 1;
    }

    .tns-controls{
      order: 2;
      margin-top: 2rem;
      align-self: flex-end;
      @media(max-width: $md){
        align-self: flex-start;
      }
    }
  }

  &__it{
    display: flex;
    flex-flow: row;

    @media(max-width: $md){
      flex-flow: column;
    }
  }

  &__cl{

    &--lf{
      width: 60%;
      padding-right: 7.3rem;

      @media(max-width: $lg){
        padding-right: 3rem;
      }

      @media(max-width: $md){
        width: 100%;
        padding-right: 0;
      }
    }

    &--rg{
      width: 40%;
      display: flex;
      align-items: center;

      @media(max-width: $md){
        width: 100%;
      }
    }
  }

  a{
    text-decoration: none;
  }

  &__img{
    border: 1px solid #808080;
    padding-bottom: 61%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    @media(max-width: $md){
      margin-bottom: 3rem;
    }

    a{
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }

  &__bx{
    max-width: 46.4rem;
  }

  &__date{
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 3rem;
    color: $blue;
    margin-bottom: 0.3rem;
    background-image: url('../imgs/icon-blog-calendar.svg');
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 2.5rem;
  }

  &__tit{
    font-family: $font_lexend;
    font-weight: 400;
    font-size: 2.6rem;
    line-height: 3.2rem;
    color: #000000;
    margin-bottom: 2rem;
    text-decoration: none;
  }

  &__desc{
    font-weight: 400;
    font-size: 2rem;
    line-height: 3rem;
    color: #505050;
    margin-bottom: 3.6rem;
  }

  &--post{
    & .m-blog-rel{
      &__it{
        flex-flow: column;
      }

      &__img{
        margin-bottom: 4rem;
      }

      &__feed{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        grid-gap: 4rem;

        @media(max-width: $lg){
          grid-gap: 2rem;
        }

        @media(max-width: $lg){
          grid-template-columns: repeat(1,1fr);
          grid-gap: 3rem;
        }
      }

      &__date{
        margin-bottom: 2.6rem;
      }

      &__tit{
        font-family: $font_lexend;
        font-weight: 600;
        font-size: 2rem;
        line-height: 2.5rem;
        letter-spacing: -0.02em;
        color: #222222;
        margin-bottom: 2.6rem;
      }

      &__desc{
        font-size: 1.6rem;
        line-height: 2.6rem;
        margin-bottom: 4rem;
      }

      &__cl{
        &--lf{
          width: 100%;
          padding-right: 0;
        }

        &--rg{
          width: 100%;
        }
      }
    }
  }
  &--list{
    padding-top: 8rem;
    .container{
      display: flex;
      flex-flow: column;
      align-items: center;
    }

    .grid{
      display: grid;
      grid-gap: 4rem;
      grid-template-columns: repeat(3,1fr);
      margin-bottom: 4rem;

      @media(max-width: $md){
        grid-gap: 3rem;
        grid-template-columns: repeat(1,1fr);
      }
    }
    .m-blog-rel{
      &__cn{
        width: 100%;

      }
    }
  }
}

.blog-post{
  .container{
    display: flex;
    flex-flow: row;
    max-width: 160rem;

    @media(max-width: $md){
      flex-flow: column;
    }
  }

  &__cl{
    width: 50%;

    @media(max-width: $md){
      width: 100%;
    }
  }

  &__img{
    margin-top: 5rem;
    padding-bottom: 70.5%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 2rem;
    border: 1px solid rgba(80, 80, 80, 0.1);

    @media(max-width: $md){
      margin-top: 0;
      margin-right: 0;
      margin-bottom: 3rem;
    }
  }

  &__video{
    /* margin-top: 5rem;
    padding-bottom: 70.5%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 2rem;
    width: 100%;
    height: auto; */
    position:relative;padding-bottom:56.25%;height:0;overflow:hidden;

    @media(max-width: $md){
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 3rem;
      }
     video {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%
    }
  }

  .m-blog-slider{
    &__date{
      margin-bottom: 2rem;
    }
  }

  &__bx{
    max-width: 58rem;
    padding-left: 8rem;

    @media(max-width: $lg){
      padding-left: 0;
    }

    @media(max-width: $md){
      max-width: 100%;
    }
  }

  &__tit{
    font-family: $font_lexend;
    font-weight: 700;
    font-size: 4rem;
    line-height: 5rem;
    letter-spacing: -0.02em;
    color: $blue;
    margin-bottom: 5rem;
  }

  &__cn{
    font-size: 1.6rem;
    line-height: 2.6rem;
    color: #505050;
    margin-bottom: 8rem;

    p{
      font-size: 1.6rem;
      line-height: 2.6rem;
      margin-bottom: 1.6rem;

      &:nth-child(1){
        font-size: 2rem;
        line-height: 3rem;
      }

      strong{
        font-weight: 600;
      }

      a{
        color: #505050;
        text-decoration: none;
        font-weight: 600;
      }

    }
  }

  &__hashtags{
    margin-bottom: 8rem;

    a{
      font-weight: 600;
      color: #505050;
      text-decoration: none;
      font-size: 1.6rem;
      line-height: 2.6rem;
    }

  }

  .link{
    &--linkedin{
      margin-top: 4rem;
    }
  }
}
