.m-contact{
  background-color: $blue_light;
  padding: 11.1rem 0; 

  @media(max-width:$lg){
    padding: 5rem 0;
  }

  .container{
    display: flex;
    @media(max-width:$md){
      flex-flow: column;
      padding: 0;
    }
  }

  &__bx{
    @media(max-width:$md){
      padding: 0 2rem;
    }
  }

  &__cl{
    &--lf{
      width: 33.3334%;
      padding-right: 5rem;
      padding-top: 2rem;

      @media(max-width:$md){
        width: 100%;
        padding: 0;
      }
    }

    &--rg{
      width: 66.66667%;
      background-color: $white;
      padding: 8.7rem 6rem 5.7rem;

      @media(max-width:$md){
        width: 100%; 
        padding: 3.5rem 0rem;
      }

    }
  }

  &__txt{
    font-size: 2rem;
    line-height: 3rem;
    margin-bottom: 7rem;
    width: 100%;

    @media(max-width:$md){
      margin-bottom: 3rem; 
    }
  }

  &--work{
    .txt{
      font-size: 2rem;
      line-height: 3.6rem;

      strong{
        font-weight: 600;
      }

    }
  }
}

.contact-item{
  font-size: 2rem;
  line-height: 3.6rem;
  color: $black;
  margin-bottom: 4rem;

  &__tit{
    font-weight: 700;
  }

  &__txt{
    padding-left: 2.8rem;
    background-position: left top 1rem;
    background-repeat: no-repeat;
    background-size: 1.5rem;

    &--place{
      background-image: url('../imgs/icon-contacto-location.svg');
    }
  
    &--tel{
      background-image: url('../imgs/icon-contacto-phone.svg');
  
    }
  }

  
}