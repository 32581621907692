.popup-content {
  padding: 10rem 7rem;

  @media(max-width: $md){
    padding: 3rem 1rem;
  }

  &--sm{
    padding: 8rem;

    @media(max-width: $md){
      padding: 3rem 1rem;
    }
  }

  .video-responsive{
    margin-bottom: 5rem;
    @media(max-width: $md){
      margin-bottom: 3rem;
    }
  }
}

.fancybox{
  &__content{
    padding: 0;
    max-width: 120rem!important;
    width: 100%;
    > .carousel{
      &__button{
        &.is-close{
          top: 2rem;
          right: 2rem;
          background-image: url('../imgs/icon-close-fancybox.svg');
          width: 6.3rem;
          height: 6.2rem;

          @media(max-width: $md){
            background-size: 3rem;
            top: 1rem;
            right: 1rem;
            width: 3rem;
            height: 3rem;
          }

          svg{
            display: none;
          }
        }
      }
    }
  }
}

.popup-professional {
  padding: 10rem;

  @media(max-width: $lg){
    padding: 5rem;
  }

  &.fancybox{
    &__content{
      max-width: 130rem!important;
    }
  }

  p{
    text-align: center;
    font-size: 2rem;
    line-height: 3rem;
    color: #505050;

    @media(max-width: $lg){
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }

  .btns{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 10rem;
    margin-top: 5rem;

    @media(max-width: $md){
      grid-template-columns: repeat(1,1fr);
      grid-gap: 3rem
    }

    .btn{
      max-width: 100%;
      width: 100%;
      height: 6rem;
      font-size: 2.5rem;
      line-height: 3.1rem;
    }
  }

  .is-close{
    display: none;
  }

}


#responsePopupContact{
    &.fancybox{
        &__content{
            padding: 10rem 2rem;
            max-width: 50rem !important;
            .alert{
                background-color: $white;
                color: $blue;
                text-align: center;
            }
        }
    }
}
