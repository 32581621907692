/* anim css*/
.transItem {
  transition: opacity 600ms ease-in-out, transform 600ms ease-in-out;}
  
.transdelay05 {
  transition-delay: 0.5s;}

.transdelay1{
  transition-delay: 1s;
}

.transItem.fadeIn{
opacity: 0;}

.transItem.fromTop{
opacity: 0;transform: translateY(-200px);}

.transItem.fromBottom{
opacity: 0;transform: translateY(10px);}

.transItem.fromRight{
opacity: 0;transform: translateX(200px);}

.transItem.fromLeft{
opacity: 0;transform: translateX(-200px);}

.transItem--active.fadeIn {
opacity: 1;}
.transItem--active.fromTop {
opacity: 1;transform: translateY(0);}
.transItem--active.fromBottom {
opacity: 1;transform: translateY(0);}
.transItem--active.fromRight {
opacity: 1;transform: translateX(0);}
.transItem--active.fromLeft {
opacity: 1;transform: translateX(0);}

.transdelay0_25 {
  transition-delay: 0.25s;
}

.transdelay0_5 {
  transition-delay: 0.250s;
}

.transdelay0_75 {
  transition-delay: 0.75s;
}

.transdelay1 {
  transition-delay: 1s;
}

.transdelay1_25 {
  transition-delay: 1.25s;
}
