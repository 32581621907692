.providers{
  &__intro{
    max-width: 103rem;
    margin: 0 auto;
    font-weight: 400;
    font-size: 2rem;
    line-height: 3rem;
    text-align: center;
    color: #505050;
    margin-bottom: 11.5rem;
  }

  &__tit{
    font-family: $font_lexend;
    font-weight: 700;
    font-size: 4rem;
    line-height: 5rem;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: $blue;
    margin-bottom: 4rem;
  }

  &__rw{
    margin-bottom: 8rem;
  }

  &__bx{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 4rem;

    @media (max-width:$lg){
      grid-template-columns: repeat(2,1fr);
    }

    @media (max-width:$sm){
      grid-template-columns: repeat(1,1fr);
      grid-gap: 3.4rem;
    }  
  }

  &__cn{
    background: rgba(80, 80, 80, 0.05);
    padding: 3.4rem 4.5rem;
    // position: absolute;
    // width: calc(100% - 9rem);
    // height: calc(100% - 6.8rem);
    display: block;
    min-height: 37.4rem;
    box-sizing: border-box;

    @media (max-width:$md){
      position: relative;
      min-height: auto;
      padding: 3rem;
    } 

    img{
      margin-bottom: 2rem;
    }

    p{
      font-size: 1.6rem;
      line-height: 2.6rem;
      margin-bottom: 1.6rem;
      color: #505050;

      strong{
        font-family: $font_lexend;
        font-weight: 600;
        font-size: 2rem;
        line-height: 2.5rem;
        letter-spacing: -0.02em;
        color: #222222;

      }
    }

    a{
      font-weight: 700;
    }
  }

  &__it{
    background: rgba(80, 80, 80, 0.05);
    padding-bottom: 105.45%;
    position: relative;

    @media (max-width:$md){
      padding-bottom: 3.4rem;
    } 
    
  }
}

.providers-icons {
  img {
    max-width: 3.8rem;
    width: 100%;
    margin-right: 0.8rem;
  }
}

.provider {
  max-width: 62.2rem!important;
  width: 100%;
}