form{
  .btn{
    // max-width: 26.1rem;
    font-size: 2.5rem;
    line-height: 3.1rem;
    height: 6rem;
  }
  
  label{
    width: 100%;
    display: block;
    &.atach{
      margin-bottom: 2rem;
    }
  }
}

.treatment{
  padding: 3rem 0 3rem;
  
  strong{
    font-weight: 600;
  }

  p{
    margin: 0;
    font-size: 1.2rem;
    line-height: 2.2rem;
  }
}

.form{

  

  &--sm{
    max-width: 50rem;
    margin: 0 auto; 
  }


  &--md{
    max-width: 70rem;
    margin: 0 auto; 
  }}

.form-control{
  font-family: $font_opensans;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;
  color: #000000;
  padding: 2.2rem 0;
  margin-bottom: 2rem;
  border-radius: 0;
  border: 0;
  border-bottom: 0.1rem solid $black;
  display: block;
  width: 100%;
  opacity: 1;
  background-color: transparent;

  &:focus-within{
    outline: none;
  }

  &::placeholder{
    opacity: 1;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 3rem;
    color: #000000;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
  }

}

.form-group{
  display: grid;
  grid-gap: 0 3rem;
  grid-template-columns: repeat(2,1fr);
  
  @media(max-width:$md){
    grid-template-columns: repeat(1,1fr);
  }
}

.form-footer {
  display: flex;
  flex-flow: row;
  width: 100%;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem;

  @media(max-width:$md){
    flex-flow: column;
  }

  &__cl{
    width: 50%;

    @media(max-width:$md){
      width: 100%;
    }

    &--rg{
      display: flex;
      justify-content: end;

      @media(max-width:$md){
        justify-content: flex-start;
        margin-top: 2rem;
      }
    }
  }

  &--work{
    flex-flow: column;

    .form-footer{
      &__cl{
        width: 100%;

        .btn{
          max-width: 26.1rem;
        }
      }
    }
  }
}


.custom-checkbox {
  display: block;
  margin-bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center; 
  font-family: $font_opensans;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 2.6rem;
  text-align: left;
  color: #000;
  margin-bottom: 2rem;
  position: relative;

  &__it{
    position: relative;
    margin-bottom: 10px;
    input{
      margin-top: 5px; 
    }
  }

  & input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    // display: none;
    cursor: pointer;
    left: 0;
    // top: 0;
    position: absolute;
    z-index: -1;
    height: 1px;
    top: 0;

    &:checked + label:after{
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 2.5rem;
      height: 2.5rem;
      background-color: transparent;
      font-weight: 700;
      border-radius: 0;
      color: $blue;
      background-image: url('../imgs/icon-tick.svg');
      background-size: 1.6rem;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  & label {
      position: relative;
      cursor: pointer;
      font-weight: 300;
      font-family: $font_opensans;
      font-size: 1.6rem;
      line-height: 3rem;
      text-align: left;
      color: $black;
      padding-left: 4rem;

      &:before {
        content:'';
        -webkit-appearance: none;
        background-color: transparent;
        border: 0.1rem solid $black;
        box-shadow: none;
        padding: 0;
        display: inline-block;
        position: absolute;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 1.2rem;
        padding: 0;
        top: 0rem;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 0;
        background: $white;
        border: 0.1rem solid $blue;
        left: 0;
      }

      & a{
        color: $blue; 
      }
  }

  &.custom-checkbox{
    &--list{
      flex-flow: column;
      align-items: flex-start;
      padding: 2.2rem 0;

      p{
        margin-bottom: 2rem;
      }
  
      label{
        margin-bottom: 0.6rem;
      }
    }
  }
}

.alert {
  background-color: rgba(86,146,201,0.8);
  font-size: 1.4rem;
  padding: 1rem 2rem;
  color: white;
}

.js-feedback {
  &.custom{
    .alert{
      background-color: transparent;
      color: $black;
      text-align: center;
      // padding: 12rem 0;
      font-size: 1.6rem;
      line-height: 2.2rem;
      margin-top: 2rem;
      font-style: italic;
      font-weight: 700; 
    }
  }
}