@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@400;600;700&family=Open+Sans:wght@400;600;700&display=swap');


$font_opensans: 'Open Sans', sans-serif;
$font_lexend: 'Lexend Deca', sans-serif;

//colors
$black: #222;
$white: #fff;
$blue: #5692C9;
$blue_light: #EEF4FA;


//mediaqueries

$sm: 575.98px;
$md: 767.98px;
$lg: 991.98px;
$xl: 1199.98px;
$xxl: 1399.98px;