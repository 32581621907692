.menu{
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.2rem;
  text-align: center;
  color: $white;
  margin-top: 4.4rem;

  ul{
    display: flex;
    list-style: none;
  }

  .menu{
    &__submenu{
      background: rgba(255, 255, 255, 0.8);
      padding: 1rem 2.5rem;
      min-width: 26.4rem;
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      position: absolute;
      top: 100%;
      // margin-top: 2rem;
      display: none;

      &.js-menu{
        display: flex;
      }

      .menu-item{
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 2.6rem;
        color: #6C6B6B;
        text-decoration: none;
        padding: 0.5rem 0;
        width: 100%;
        display: block;
        text-align: left;

        @media(max-width: $xxl){
          font-size: 1.4rem;
          line-height: 2.4rem;
        }

        &:hover{
          color: $blue;
        }

      }
    }
  }

  .menu-item{
    font-weight: 600;
    font-size: 1.6rem;
    // line-height: 2.2rem;
    text-align: center;
    color: $white;
    margin-top: 4.4rem;
    text-decoration: none; 
    text-transform: uppercase;
    margin: 0 2rem;
    position: relative;

    a{
      color: $white;
    }

    @media(max-width:$xxl){
      margin: 0 1rem;
      font-size: 1.4rem;
      line-height: 2.4rem;
    }

    &.active{
      &::after{
        content: "";
        width: 7rem;
        height: 0.1rem;
        background-color: white;
        display: block;
        position: absolute;
        margin-top: 0.7rem;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        transition-duration: 0.3s;
        transition-property: transform;
      }
    }

    &.submenu{
      margin-right: 2.5rem;
      i{
        content: '';
        background-image: url('../imgs/icon-top-down.svg');
        position: absolute;
        margin-left: 0;
        width: 1.5rem;
        height: 1.5rem;
        right: -24px;
        display: inline-block;
        top: 3px;
        transition-duration: 0.3s;
        transition-property: transform;
        cursor: pointer;
      }

      &.js-menu{
        i{
          transform: rotate(180deg);
        }
      }
    }
  }

  @media(max-width: $xl){
    display: none;
  }
}

.m-hamb{
  display: none;
  width: 100%;
  z-index: 99;
  position: fixed;
  top: 0;
  background-color: white;
  min-height: 100vh;
  flex-flow: column;

  &.js-show{
    @media(max-width:$xl){
      display: flex;
    }
  }

  &__intro{
    padding: 2rem 2rem 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__info{
    display: flex;
    flex-flow: column;
    margin: 0 2rem;
    padding-top: 2.7rem;

    .info-item{
      color: #6C6B6B;
      margin: 1.3rem 0;
      &--mail{
        background-image: url('../imgs/icon-top-email-grey.svg');
      }
    
      &--tel{
        background-image: url('../imgs/icon-top-phone-grey.svg');
      }
    
      &--log{
        background-image: url('../imgs/icon-top-login-grey.svg');
      }

      &--log-in{
        background-image: url('../imgs/icon-menu-mobile-user.svg');
      }

      &--log-out{
        background-image: url('../imgs/icon-menu-mobile-logout.svg');
        margin-left: 1.5rem;
      }
    }

    .lang-sel{
      margin-top: 1.3rem;
      display: flex;
      flex-flow: row;

      &__current{
        display: inline-flex;
        color: #6C6B6B;
        flex-flow: row-reverse;

        &::after{
          background-image: url('../imgs/icon-top-down-grey.svg');
          position: relative;
          margin-left: 0;
          margin-right: 1rem;
        }

        &.js-show{
          &::after{
            transform: rotate(-90deg);
          }
        }
      }
      &__toggle{
        right: auto;
        flex-flow: row;
        position: relative;
        padding: 0;
        margin: 0;
        background: transparent;
        box-shadow: none;
      }

      &__item{
        padding: 0 1rem;
      }

    }

  }

  .menu{
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-top: 1px solid rgba(86, 146, 201, 0.25);
    margin: 0 2rem;

    &__submenu{
      &.js-menu{
        display: flex;
        position: relative;
        top: auto;
        margin: 0;
      }
    }

    > ul{
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      padding: 0;

      &.js-menu{
        display: flex;
        position: relative;
        top: auto;
        margin: 0;
      }

      li{
        display: flex;
        flex-flow: column;
        width: 100%;
      }
    }

    .menu-item{
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2.2rem;
      color: #6C6B6B;
      padding: 2rem 0;
      border-bottom: 1px solid rgba(86, 146, 201, 0.25);
      width: 100%;
      text-align: left;
      margin: 0;
      position: relative;

      a{
        color: #6C6B6B;
      }

      &.submenu{

        
        
        i{
          content: ''; 
          background-image: url('../imgs/icon-top-down-grey.svg');
          width: 1.5rem;
          height: 1.5rem;
          top: 3rem;
          transform: translateY(-50%) rotate(-90deg);
          right: 0;

          &.js-menu{
            transform: translateY(-50%) rotate(0deg);
          }
        }
      }
    }
  }
  
}

.m-toggle{
  display: none;
  width: 2.6rem;
  height: 3rem;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  position: relative;
  right: -3rem;
  top: auto;
  z-index: 99999;
  padding: 0;

  @media(max-width: $xl) {
    display: block;
  }

  & span{
    display: block;
    position: absolute;
    height: 0.3rem;
    width: 100%;
    background: $white;
    border-radius: 0;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2){
      top: 1rem;
      right: 0;
      left: auto;
    }

    &:nth-child(3){
      top: 1rem;
      right: 0;
      left: auto;
    }

    &:nth-child(4) {
      top: 2rem;
    }
  }

  &.js-open{
    & span{

      &:nth-child(1) {
        top: 1rem;
        width: 0%;
        left: 50%;
      }

      &:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        max-width: 100%;
      }

      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        max-width: 100%;
      } 

      &:nth-child(4) {
        top: 1rem;
        width: 0%;
        left: 50%;
      }
    }
  }
}

.m-hamb-close{
  background-image: url('../imgs/icon-close.svg');
  border: 0;
  padding: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 1.5rem;
  background-position: center;
  width: 1.5rem;
  height: 1.5rem;
}