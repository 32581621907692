.breadcrumbs{
  padding: 8rem 0 2.3rem;
  font-size: 1.6rem;
  line-height: 2.6rem;
  color: $blue;
  text-decoration: none;
  width: 100%;
  overflow: hidden;

  .container{
    display: flex;
    flex-flow: row;
    width: 100%;
    flex-wrap: wrap;
  }

  a{
    margin-right: 2.2rem;
    text-decoration: none;
    color: $blue;
    font-weight: 400;

    span{
      margin-right: 0;
    }
  }
  
  span{
    margin-right: 2.2rem;
  }

  &__item{
    font-weight: 600;
    color: #505050;
  }
}